@tailwind base;
@tailwind components;
@tailwind utilities;

//#region Non-treeshakable
.animate-tickDraw {
  @apply animate-[tickDraw_0.75s_ease-in-out_forwards];
}

.animate-tickFill {
  @apply animate-[tickFill_0.25s_linear_forwards_0.75s];
}

app-svg-icon[name="help-circle"] {
  cursor: help;
}
//#endregion

@layer base {
  html {
    @apply font-Inter;
  }
}

@layer components {
  //#region Links
  .link-0 {
    @apply font-medium focus:outline-none focus:text-primary-400 text-primary-700;
  }
  //#endregion
}

@layer utilities {
  //#region Focus rings
  .focus-ring-primary-0 {
    @apply focus:outline-none focus:ring focus:ring-primary-100;
  }

  .focus-ring-primary-1 {
    @apply focus:outline-none focus:ring focus:ring-primary-200;
  }

  .focus-ring-primary-2 {
    @apply focus:outline-none focus:ring focus:ring-primary-300;
  }

  .focus-ring-error-0 {
    @apply focus:outline-none focus:ring focus:ring-error-100;
  }

  .focus-ring-error-1 {
    @apply focus:outline-none focus:ring focus:ring-error-200;
  }

  .focus-ring-error-2 {
    @apply focus:outline-none focus:ring focus:ring-error-300;
  }
  //#endregion

  //#region Custom scroll container
  .custom-scroll-container {
    @apply overflow-auto;
  }

  .custom-scroll-container::-webkit-scrollbar {
    @apply w-2;
  }

  .custom-scroll-container::-webkit-scrollbar-thumb {
    @apply bg-gray-300 rounded-xl;
  }

  .custom-scroll-container::-webkit-scrollbar-track {
    @apply bg-primary-800 rounded-xl;
  }
  //#endregion

  //#region Elevate
  .elevate-0 {
    @apply shadow-[0px_1px_2px_0px_#1018280F,_0px_1px_3px_0px_#1018281A];
  }
  //#endregion
}
