div.mat-mdc-menu-panel {
  @apply max-w-lg;

  .mat-mdc-menu-content:not(:empty) {
    @apply p-0;

    .mat-mdc-menu-item {
      @apply h-auto whitespace-normal leading-none p-3;
    }
  }
}
