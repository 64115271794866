mat-checkbox.mat-mdc-checkbox {
  @apply inline-flex align-bottom h-6 pr-0;
}

.mat-mdc-checkbox.ng-invalid.ng-untouched .mdc-checkbox__background {
  @apply border-gray-300 #{!important};
}

.mat-mdc-checkbox.ng-invalid.ng-touched .mdc-checkbox__background {
  @apply border-error-500 #{!important};
}
