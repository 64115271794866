.mat-mdc-table {
  @apply w-full border-separate rounded-lg elevate-0;
}

.mat-mdc-table .mat-mdc-header-cell {
  background-color: rgb(247, 249, 251) !important;
  @apply pl-6 pr-6 py-3 first:rounded-tl-lg last:rounded-tr-lg first:border-l last:border-r border-t border-b border-gray-200 bg-gray-50 text-sm leading-sm font-medium text-gray-500;
}

.mat-mdc-table .mat-mdc-header-row {
  @apply h-auto items-stretch;
}

.mat-mdc-table .mat-mdc-row {
  @apply h-[4.5rem] items-stretch bg-white;
}

.mat-mdc-table .mat-mdc-row .mat-mdc-cell {
  @apply pl-6 pr-6 border-gray-200 first:border-l last:border-r;
}

.mat-mdc-table:not(.with-footer) .mat-mdc-row:last-child .mat-mdc-cell {
  @apply first:rounded-bl-lg last:rounded-br-lg;
}

.mat-mdc-table .mat-sort-header-arrow {
  @apply text-gray-500;
}

.mat-mdc-table [mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
.mat-table [mat-sort-header].cdk-program-focused .mat-sort-header-container {
  @apply border-b-0;
}

.mat-mdc-table [mat-sort-header].cdk-keyboard-focused .mat-sort-header-container .mat-sort-header-content,
.mat-table [mat-sort-header].cdk-program-focused .mat-sort-header-container .mat-sort-header-content {
  @apply text-primary-500;
}

.mat-mdc-table [mat-sort-header].cdk-keyboard-focused .mat-sort-header-arrow,
.mat-table [mat-sort-header].cdk-program-focused .mat-sort-header-arrow {
  @apply text-primary-500;
}

.mat-mdc-table .mat-mdc-footer-row {
  @apply items-stretch rounded-bl-lg rounded-br-lg;
}

.mat-mdc-table .mat-mdc-footer-cell {
  @apply border-b border-l border-r border-gray-200 rounded-bl-lg rounded-br-lg;
}
