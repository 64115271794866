.dialog-full-screen mat-dialog-container.mat-mdc-dialog-container {
  @apply w-screen h-screen rounded-none p-0;
}

mat-dialog-container .mat-mdc-dialog-surface {
  @apply p-6 rounded-xl #{!important};
}

.spinner-dialog .mat-mdc-dialog-container {
  @apply bg-white/30 shadow-none drop-shadow-2xl;
}

.dialog-backdrop-blur {
  @apply backdrop-blur bg-black/30;
}
