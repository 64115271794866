.mat-mdc-form-field {
  @apply block relative #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-error-wrapper {
  @apply pl-0;
}

.mat-mdc-form-field .mat-mdc-input-element::placeholder {
  @apply text-gray-300 #{!important};
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  @apply pr-0 pl-0 pb-0 flex flex-col bg-white #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper {
  @apply p-0 font-extralight;
}

.mat-mdc-form-field .mat-mdc-form-field-flex {
  @apply relative #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-icon-prefix {
  @apply absolute top-0 bottom-0 z-[2] flex items-center px-3 text-gray-500 #{!important};
}

.mat-mdc-form-field.mat-mdc-form-field-has-label .mat-mdc-form-field-icon-prefix {
  @apply top-[1.625rem] #{!important};
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-icon-prefix {
  @apply text-primary-500 #{!important};
}

.mat-mdc-form-field.ng-invalid.mat-mdc-form-field-invalid .mat-mdc-form-field-icon-prefix {
  @apply text-error-500 #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-icon-prefix ~ .mat-mdc-form-field-infix .mat-mdc-input-element {
  @apply pl-[2.375rem] #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
  @apply absolute top-0 bottom-0 right-0 z-[2] flex items-center px-3 text-gray-500 #{!important};
}

.mat-mdc-form-field .mdc-line-ripple {
  @apply hidden #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  @apply static #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-infix {
  margin-top: -1.1rem;
  @apply p-0 border-none flex flex-col-reverse w-auto #{!important};
}

.mat-mdc-form-field .mat-mdc-input-element {
  @apply border border-gray-300 rounded-md py-2 px-3 text-sm leading-sm box-border text-gray-500 border-solid mt-0 #{!important};
}

.mat-mdc-form-field.with-suffix .mat-mdc-input-element {
  @apply pr-[2.375rem] #{!important};
}

.mat-mdc-form-field .mat-mdc-input-element:disabled {
  @apply bg-gray-50 #{!important};
}

.mat-mdc-form-field.mat-focused .mat-mdc-input-element {
  @apply border-primary-500 text-primary-500 #{!important};
}

.mat-mdc-form-field.ng-invalid.mat-form-field-invalid .mat-mdc-input-element {
  @apply border-error-500 text-error-500 #{!important};
}

.mat-mdc-form-field mdc-floating-label {
  top: 22px !important;
}

.mat-mdc-form-field.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field.mat-form-field-can-float
.mat-form-field-autofill-control:-webkit-autofill
+ .mat-form-field-label-wrapper
.mat-form-field-label {
  @apply transform-none w-auto #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-error {
  @apply text-xs leading-xs font-medium pt-2 #{!important};
}

.mat-mdc-form-field .mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, .54);
  @apply text-xs leading-xs pt-2 font-normal #{!important};
}
